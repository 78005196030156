import API from "../services/services";

class ManageBloodShipmentAPI {
  getList(params) {
    return API.get("/blood_discarded/list", { params });
  }
  getBloodDiscardedReport(payload) {
    return API.post("/blood_discarded/report_monthly", payload);
  }
  add(payload) {
    return API.post("blood_discarded/store", payload);
  }
  checkingBarcode(params) {
    return API.get(`blood_discarded/check/${params}`);
  }
}

export default new ManageBloodShipmentAPI();
